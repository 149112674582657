import React from 'react';
import {lookupEmpty} from '../../assets/images/images';
import AttentionLottie from '../../assets/lotties/AttentionLottie';
import {useTour} from '../../hooks';
import Modal from '../Modal';
import PrimaryButton from '../PrimaryButton';

export default function EasyLookupTour({updateTourState, loading, DATA}) {
  const {tourModal, setTourModal, handleTourComplete} = useTour(
    'easy_lookup_tour',
    loading,
    DATA,
    updateTourState,
  );

  if (DATA?.length === 0) {
    return (
      <Modal
        show={tourModal}
        onClose={setTourModal}
        extraModalClasses="max-w-fit overflow-y-auto h-fit max-h-[95%] sm:max-h-full relative text-left"
        modalPosition="justify-center items-end"
        modalPadding="pb-[20vh] lg:pr-[26.46%] lg:pl-[41.38%] px-6"
        noBottomPadding
        borderRadius="rounded"
        modalBg="bg-white"
        pointerEventsNone
        overlayBackground
      >
        <div
          className="max-w-[276px] p-6 h-fit flex flex-col items-center justify-center outline-none m-auto text-center"
          tabIndex={0}
        >
          <img src={lookupEmpty} alt="" width={94} height={94} />

          <p className="mt-2 mb-6 text-sm text-body">
            Perform lookup to verify the identity of your users
          </p>

          <PrimaryButton
            buttonText="Perform Lookup"
            xPadding="px-[35px]"
            className="pointer-events-none"
          />

          <button
            className="mt-6 text-brandBlue text-xs font-medium focus-visible:outline-brandBlue"
            onClick={handleTourComplete}
          >
            Dismiss
          </button>
        </div>
      </Modal>
    );
  }
  return (
    <Modal
      show={tourModal}
      onClose={setTourModal}
      extraModalClasses="max-w-fit overflow-y-auto h-fit max-h-[95%] sm:max-h-full relative text-left"
      modalPosition="justify-center sm:justify-end"
      modalPadding="p-6 pt-22"
      noBottomPadding
      borderRadius="rounded"
      modalBg="bg-transparent"
      pointerEventsNone
      overlayBackground
    >
      <div className="flex flex-col relative outline-none" tabIndex={0}>
        <PrimaryButton
          buttonText="Perform Lookup"
          xPadding="px-[35px]"
          className="hidden sm:flex ml-auto pointer-events-none"
        />
        <AttentionLottie
          style={{
            width: '48px',
            height: '48px',
            transform: 'matrix(0, 1, 1, 0, 0, 0)',
          }}
          className="self-end mr-10 absolute top-12 hidden sm:flex"
        />
        <div className="p-2 mt-5 flex flex-col items-start gap-2 bg-white mr-[75px] rounded">
          <p className="text-sm font-medium text-grey">Perform Lookup</p>
          <p className="text-sm text-body max-w-[187px]">
            Perform lookup to verify the identity of your users
          </p>
          <button
            className="text-brandBlue text-xs font-medium focus-visible:outline-brandBlue"
            onClick={handleTourComplete}
          >
            Dismiss
          </button>
        </div>
      </div>
    </Modal>
  );
}
