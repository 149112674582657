// SVGs

export {default as dropdownFillWhite} from './dropdown-fill-white.svg';
export {default as teamIllustration} from './team-illustration.svg';
export {default as actionsOnboard} from './actions-onboard.svg';
export {default as dropdownNoFill} from './dropdown-nofill.svg';
export {default as uncheckedLarge} from './unchecked-large.svg';
export {default as backWhitefill} from './back-whitefill.svg';
export {default as actionsLookup} from './actions-lookup.svg';
export {default as removeService} from './remove-service.svg';
export {default as dropdownBlue} from './dropdown-blue.svg';
export {default as actionsTopup} from './actions-topup.svg';
export {default as checkedLarge} from './checked-large.svg';
export {default as downloadGrey} from './download-grey.svg';
export {default as dropdownGrey} from './dropdown-grey.svg';
export {default as notification} from './notification.svg';
export {default as filterWhite} from './filter-white.svg';
export {default as backNofill} from './back-nofill.svg';
export {default as closeModal} from './close-modal.svg';
export {default as uploadCsv} from './upload-csv.svg';
export {default as unchecked} from './unchecked.svg';
export {default as hamburger} from './hamburger.svg';
export {default as google} from './google-logo.svg';
export {default as apiTools} from './api-tools.svg';
export {default as idCheck} from './id_check.svg';
export {default as plusGrey} from './plus-grey.svg';
export {default as calendar} from './calendar.svg';
export {default as dropdown} from './dropdown.svg';
export {default as eyeDark} from './eye-dark.svg';
export {default as checked} from './checked.svg';
export {default as nigeria} from './nigeria.svg';
export {default as uganda} from './uganda.svg';
export {default as filter} from './filter.svg';
export {default as search} from './search.svg';
export {default as kenya} from './kenya.svg';
export {default as ghana} from './ghana.svg';
export {default as logo} from './logo.svg';
export {default as eye} from './eye.svg';
export {default as plus} from './plus.svg';
export {default as back} from './back.svg';
export {default as lock} from './lock.svg';
export {default as edit} from './edit.svg';
export {default as info} from './info.svg';
export {default as trash} from './trash.svg';
export {default as orIcon} from './orIcon.svg';
export {default as andIcon} from './andIcon.svg';
export {default as trashRed} from './trashRed.svg';
export {default as trashDarkRed} from './trashDarkRed.svg';
export {default as times} from './times.svg';
export {default as dojahFavicon} from './favicon.svg';
export {default as wallet} from './wallet.svg';
export {default as failed} from './failed.svg';
export {default as picture} from './picture.svg';
export {default as widgetFace} from './face.svg';
export {default as transfer} from './transfer.svg';
export {default as paystack} from './paystack.svg';
export {default as failed_t} from './failed-t.svg';
export {default as settings} from './settings.svg';
export {default as playBlue} from './play-blue.svg';
export {default as apiCalls} from './api-calls.svg';
export {default as flowsNav} from './flows-nav.svg';
export {default as notFound} from './not-found.png';
export {default as appEmpty} from './app-empty.png';
export {default as copyBlue} from './copy-blue.svg';
export {default as copyGrey} from './copy-grey.svg';
export {default as copyWhite} from './copy-white.svg';
export {default as biodataIc} from './biodata-ic.svg';
export {default as addressIc} from './address-ic.svg';
export {default as enlargeIc} from './enlarge-ic.svg';
export {default as successful} from './successful.svg';
export {default as customFlow} from './custom-flow.png';
export {default as teamIc} from './team-breadcrumb.svg';
export {default as webhookNav} from './webhook-nav.svg';
export {default as widgetBack} from './widget-back.svg';
export {default as emptyState} from './empty-state.png';
export {default as amlCheckIc} from './aml-check-ic.svg';
export {default as apiLogsNav} from './api-logs-nav.svg';
export {default as lookupEmpty} from './lookup-empty.png';
export {default as overviewNav} from './overview-nav.svg';
export {default as widgetCheck} from './widget-check.svg';
export {default as chevronLeft} from './chevron-left.svg';
export {default as verification} from './verification.svg';
export {default as dashboardNav} from './dashboard-nav.svg';
export {default as dropdownFill} from './dropdown-fill.svg';
export {default as chevronRight} from './chevron-right.svg';
export {default as profileIc} from './profile-breadcrumb.svg';
export {default as verifiedBadge} from './verified-badge.svg';
export {default as closeWidget} from './widget-close-btn.svg';
export {default as hyperLinkGrey} from './hyperlink-grey.svg';
export {default as billingIc} from './billing-breadcrumb.svg';
export {default as corruptedFile} from './corrupted-file.png';
export {default as developersNav} from './developers-nav.svg';
export {default as identification} from './identification.png';
export {default as apiLogsIc} from './api-logs-breadcrumb.svg';
export {default as hyperLinkWhite} from './hyperlink-white.svg';
export {default as chevronRightSm} from './chevron-right-sm.svg';
export {default as flowsMobileNav} from './flows-mobile-nav.svg';
export {default as quickActionsNav} from './quick-actions-nav.svg';
export {default as widgetSteps} from './widget-liveness-steps.svg';
export {default as auditLogsIc} from './audit-logs-breadcrumb.svg';
export {default as dashboardIc} from './dashboard-breadcrumbs.svg';
export {default as complianceIc} from './compliance-breadcrumb.svg';
export {default as ipDeviceCheckIc} from './ip-device-check-ic.svg';
export {default as reconciloLogo} from './reconcilo-logo.svg';
export {default as configurationNav} from './configuration-nav.svg';
export {default as easyLookupIc} from './easy-lookup-breadcrumb.svg';
export {default as apiLogsMobileNav} from './api-logs-mobile-nav.svg';
export {default as onboardEmpty} from './empty-state-easy-onboard.png';
export {default as easyOnboardIc} from './easy-onboard-breadcrumb.svg';
export {default as quickActionsIc} from './quick-actions-breadcrumb.svg';
export {default as dashboardMobileNav} from './dashboard-mobile-nav.svg';
export {default as amlScreeningIc} from './aml-screening-breadcrumb.svg';
export {default as configurationIc} from './configuration-breadcrumb.svg';
export {default as verificationsIc} from './verifications-breadcrumbs.svg';
export {default as developersMobileNav} from './developers-mobile-nav.svg';
export {default as quickActionsMobileNav} from './quick-actions-mobile-nav.svg';
export {default as configurationMobileNav} from './configuration-mobile-nav.svg';
export {default as smiley} from './smiley.svg';
export {default as step1} from './step-one.svg';
export {default as step2} from './step-two.svg';
export {default as cup} from './action-end.svg';
export {default as suspend} from './suspend.svg';
export {default as resend} from './resend.svg';
export {default as logoutIc} from './logout.svg';
export {default as teamNav} from './team-nav.svg';
export {default as pending} from './pending.svg';
export {default as step3} from './step-three.svg';
export {default as infoRed} from './info-red.svg';
export {default as userRed} from './user_red.svg';
export {default as download} from './download.svg';
export {default as infoBlue} from './info-blue.svg';
export {default as userBlue} from './user_blue.svg';
export {default as flowLine} from './flow-line.svg';
export {default as blacklist} from './blacklist.svg';
export {default as infoGreen} from './info-green.svg';
export {default as userGreen} from './user_green.svg';
export {default as stepsPlus} from './steps-plus.svg';
export {default as windowBlue} from './window_blue.svg';
export {default as billingNav} from './billing-nav.svg';
export {default as profileNav} from './profile-nav.svg';
export {default as checkedBox} from './checked-box.svg';
export {default as eyeCapBlue} from './eyecap_blue.svg';
export {default as widgetLogo} from './widget-logo.svg';
export {default as monitorRed} from './monitor_red.svg';
export {default as settingsNav} from './settings-nav.svg';
export {default as linkExpired} from './link-expired.svg';
export {default as logoutIcGrey} from './logout-grey.svg';
export {default as actionStepIc} from './action-step.svg';
export {default as totalEvents} from './total-events.svg';
export {default as windowGreen} from './window_green.svg';
export {default as eyeCapGreen} from './eyecap_green.svg';
export {default as monitorBlue} from './monitor_blue.svg';
export {default as disclaimerIcon} from './disclaimer.svg';
export {default as blacklistRed} from './blacklist-red.svg';
export {default as uncheckedBox} from './unchecked-box.svg';
export {default as auditLogsNav} from './audit-logs-nav.svg';
export {default as allowedEvents} from './allowed-events.svg';
export {default as parallelStepIc} from './parallel-step.svg';
export {default as blockedEvents} from './blocked-events.svg';
export {default as pendingEvents} from './pending-events.svg';
export {default as easyLookupNav} from './easy-lookup-nav.svg';
export {default as baasIconWhite} from './baas-icon-white.svg';
export {default as baasIconBlack} from './baas-icon-black.svg';
export {default as closeMenuGrey} from './close-menu-grey.svg';
export {default as blacklistBlack} from './blacklist-black.svg';
export {default as easyOnboardNav} from './easy-onboard-nav.svg';
export {default as exclamationMark} from './exclamation-mark.svg';
export {default as noAccountFound} from './no-account-found.svg';
export {default as base_address_marker} from './base_address.svg';
export {default as amlScreeningNav} from './aml-screening-nav.svg';
export {default as noSearchResults} from './no-search-results.svg';
export {default as pendingCasesNav} from './pending-cases-nav.svg';
export {default as documentationNav} from './documentation-nav.svg';
export {default as verificationsNav} from './verifications-nav.svg';
export {default as conditionalStepIc} from './conditional-step.svg';
export {default as webhookMobileNav} from './webhook-mobile-nav.svg';
export {default as overviewMobileNav} from './overview-mobile-nav.svg';
export {default as welcomeScreen1} from './quick-actions-welcome-1.svg';
export {default as welcomeScreen2} from './quick-actions-welcome-2.svg';
export {default as welcomeScreen3} from './quick-actions-welcome-3.svg';
export {default as welcomeScreen4} from './quick-actions-welcome-4.svg';
export {default as welcomeScreen5} from './quick-actions-welcome-5.svg';
export {default as false_address_marker} from './false_match_address.svg';
export {default as true_address_marker} from './success_match_address.svg';
export {default as easyLookupMobileNav} from './easy-lookup-mobile-nav.svg';
export {default as easyOnboardMobileNav} from './easy-onboard-mobile-nav.svg';
export {default as amlScreeningMobileNav} from './aml-screening-mobile-nav.svg';
export {default as pendingCasesMobileNav} from './pending-cases-mobile-nav.svg';
export {default as verificationsMobileNav} from './verifications-mobile-nav.svg';
export {default as documentationMobileNav} from './documentation-mobile-nav.svg';
export {default as welcomeScreen2Shadow} from './quick-actions-welcome-2-shadow.svg';
export {default as welcomeScreen3Shadow} from './quick-actions-welcome-3-shadow.svg';
export {default as welcomeScreen4Shadow} from './quick-actions-welcome-4-shadow.svg';
export {default as infoRedSm} from './info-red-sm.svg';
export {default as compass} from './compass.svg';
export {default as developersIc} from './developers-ic.svg';
export {default as phoneNo} from './phone_no.svg';
export {default as arrowLeft} from './arrow-left.svg';
export {default as startProcess} from './start-process.svg';
export {default as link} from './link.svg';
export {default as settingsIcon} from './settingsIcon.svg';
export {default as deleteIc} from './delete.svg';
export {default as batchUpload} from './batch-upload.svg';
export {default as documentText} from './document-text.svg';
export {default as userTick} from './user-tick.svg';
export {default as downloadIc} from './download-ic.svg';
export {default as supportingDoc} from './supporting-doc.svg';
export {default as brightness} from './brightness.svg';
export {default as glassOn} from './glass-on.svg';
export {default as addressVerificationNav} from './address-verification-nav.svg';
export {default as addressVerificationMobileNav} from './address-verification-mobile-nav.svg';
export {default as addressVerificationIc} from './address-verification-ic.svg';
export {default as individualLookupImg} from './individual-lookup-img.svg';
export {default as batchLookupImg} from './batch-lookup-img.svg';
export {default as fancyCopy} from './fancy-copy.svg';
export {default as logoIcon} from './logo-icon.svg';
export {default as arrowDown} from './arrow-down.svg';
export {default as infoLg} from './info-lg.svg';
export {default as userAvatar} from './user-avatar.svg';
export {default as businessAvatar} from './business-avatar.svg';
export {default as individualLookupNav} from './individual-lookup-nav.svg';
export {default as individualLookupMobileNav} from './individual-lookup-mobile-nav.svg';
export {default as businessLookupNav} from './business-lookup-nav.svg';
export {default as businessLookupMobileNav} from './business-lookup-mobile-nav.svg';
export {default as performSingleLookup} from './perform-single-lookup.svg';
export {default as performBatchLookup} from './perform-batch-lookup.svg';
export {default as easyCreditCheckNav} from './easy-creditcheck-nav.svg';
export {default as easyCreditCheckMobileNav} from './easy-creditcheck-mobile-nav.svg';
export {default as easyCreditCheckIc} from './easy-creditcheck-breadcrumb.svg';
export {default as affordabilityIc} from './affordability-ic.svg';
export {default as kycInfoIc} from './kyc-info-ic.svg';
export {default as affordabilityEmptyState} from './affordability-empty-state.svg';
export {default as userScreeningNav} from './user-screening-nav.svg';
export {default as userScreeningMobileNav} from './user-screening-mobile-nav.svg';
export {default as businessScreeningNav} from './business-screening-nav.svg';
export {default as businessScreeningMobileNav} from './business-screening-mobile-nav.svg';
export {default as singleAmlImg} from './single-aml-img.svg';
export {default as batchAmlImg} from './batch-aml-img.svg';
export {default as customer360Nav} from './customer-360-nav.svg';
export {default as customer360MobileNav} from './customer-360-mobile-nav.svg';
export {default as customer360Ic} from './customer-360-breadcrumb.svg';
export {default as nigeriaIc} from './nigeria-ic.svg';
export {default as southAfricaIc} from './south-africa-ic.svg';
export {default as documentAnalysisNav} from './document-analysis-nav.svg';
export {default as documentAnalysisMobileNav} from './document-analysis-mobile-nav.svg';
export {default as camera} from './camera.svg';
export {default as check} from './check.svg';
export {default as infoCircle} from './info-circle.svg';
export {default as infoCircleRed} from './info-circle-red.svg';
export {default as documentAnalysisIc} from './document-analysis-breadcrumb.svg';
export {default as messagingReportNav} from './messaging-report-nav.svg';
export {default as messagingReportMobileNav} from './messaging-report-mobile-nav.svg';
export {default as messagingReportBreadcrumb} from './messaging-report-breadcrumb.svg';
export {default as customerNav} from './customer-nav.svg';
export {default as customerMobileNav} from './customer-mobile-nav.svg';
export {default as customerIndividualNav} from './customer-individual-nav.svg';
export {default as customerIndividualMobileNav} from './customer-individual-mobile-nav.svg';
export {default as customerBusinessNav} from './customer-business-nav.svg';
export {default as customerBusinessMobileNav} from './customer-business-mobile-nav.svg';
export {default as fraudFlowNav} from './fraud-flow-nav.svg';
export {default as fraudFlowMobileNav} from './fraud-flow-mobile-nav.svg';
export {default as reconciliationNav} from './reconciliation-nav.svg';
export {default as reconciliationMobileNav} from './reconciliation-nav-mobile.svg';
export {default as customListsNav} from './custom-lists-nav.svg';
export {default as customListsMobileNav} from './custom-lists-mobile-nav.svg';
export {default as transactionHistoryNav} from './transaction-history-nav.svg';
export {default as transactionHistoryMobileNav} from './transaction-history-mobile-nav.svg';
export {default as billingMobileNav} from './billing-mobile-nav.svg';
export {default as apiReportNav} from './api-report-nav.svg';
export {default as apiReportMobileNav} from './api-report-mobile-nav.svg';
export {default as settingsMobileNav} from './settings-mobile-nav.svg';
export {default as fraudMonitoringNav} from './fraud-monitoring-nav.svg';
export {default as fraudMonitoringMobileNav} from './fraud-monitoring-mobile-nav.svg';
export {default as fraudFlowBreadcrumb} from './fraud-flow-breadcrumb.svg';
export {default as pendingCasesBreadcrumb} from './pending-cases-breadcrumb.svg';
export {default as addressVerificationBreadcrumb} from './address-verification-breadcrumb.svg';
export {default as transactionHistoryBreadcrumb} from './transaction-history-breadcrumb.svg';
export {default as apiReportBreadcrumb} from './api-report-breadcrumb.svg';
export {default as matched} from './matched.svg';
export {default as unmatched} from './unmatched.svg';
export {default as govDataNav} from './government-data-nav.svg';
export {default as govDataMobileNav} from './government-data-breadcrumb.svg';
export {default as govDataBreadcrumb} from './government-data-mobile-nav.svg';
export {default as easyDetectNav} from './easy-detect-nav.svg';
export {default as easyDetectMobileNav} from './easy-detect-mobile-nav.svg';
export {default as easyDetectBreadcrumb} from './easy-detect-breadcrumb.svg';
export {default as overviewReconciliationNav} from './overview-new-nav.svg';
export {default as settingsReconciliationNav} from './settings-new-nav.svg';
export {default as discrepanciesNav} from './discrepancies-nav.svg';
export {default as projectsNav} from './projects-nav.svg';
export {default as transactionsNav} from './transactions-nav.svg';
export {default as usersNav} from './users-nav.svg';
export {default as overviewCashflow} from './overview-cashflow.svg';
export {default as overviewInflow} from './overview-inflow.svg';
export {default as reconTransact} from './recon-transact.svg';
export {default as overviewOutflow} from './overview-outflow.svg';
export {default as overviewCustomers} from './overview-customers.svg';
export {default as feesCharged} from './fees-charged.svg';
export {default as customersMobileNav} from './customers-mobile-nav.svg';
export {default as projectsMobileNav} from './recon-projects.svg';
export {default as transactionsMobileNav} from './recon-transactions.svg';
export {default as customerMail} from './customer-mail.svg';
export {default as transactionCount} from './transaction-count.svg';
export {default as autoLink} from './auto-link.svg';
export {default as manualLink} from './manual-link.svg';
export {default as internalRecords} from './internal-records.svg';
export {default as externalRecords} from './external-records.svg';
export {default as automaticSuccess} from './automatic-success.svg';
export {default as activeCheck} from './active-check-icon.svg';
export {default as reconciliationAuthBg} from './reconciliation-auth-bg.svg';
export {default as reconciliationLogo} from './reconcilo_logo.svg';
export {default as questionTitle} from './question-title.svg';
export {default as questionChoice} from './question-choice.svg';
export {default as dropdownSm} from './dropdown-sm.svg';
export {default as deleteQuestion} from './delete-question.svg';
export {default as trashQuestion} from './trash-question.svg';
export {default as editQuestion} from './edit-question.svg';
export {default as removeOption} from './remove-option.svg';
export {default as addQuestion} from './add.svg';
export {default as pendingIcon} from './pending_new.jpg';
export {default as timerIcon} from './time_icon.svg';
export {default as conversionIcon} from './conversion_rate.svg';
export {default as inProgress} from './in-progress.svg';
export {default as abandoned} from './abandoned.svg';
export {default as toolTip} from './tooltip-icon.svg';
export {default as southAfrica} from './southAfrica.svg';
export {default as canada} from './canada.svg';
export {default as unitedStates} from './unitedStates.svg';
export {default as aland} from './flag_of_aland.svg';
export {default as afghanistan} from './Flag_of_Afghanistan.svg';
export {default as angola} from './Flag_of_Angola.svg';
export {default as niger} from './Flag_of_Niger.svg';
export {default as andorra} from './Flag_of_Andorra.svg';
export {default as trinidad_and_tobago} from './Flag_of_Trinidad_and_Tobago.svg';
export {default as verificationAnalysis} from './verificationAnalysisIcon.svg';
export {default as exportMobile} from './exportMobile.svg';
export {default as filterMobile} from './filterMobile.svg';
export {default as baasOverview} from './baas_overview.svg';
export {default as baasTransactions} from './baas_transactions.svg';
export {default as baasWallets} from './baas_wallets.svg';
export {default as baasPayouts} from './baas_payouts.svg';
export {default as baasSettings} from './baas_settings.svg';
export {default as baasMobile} from './baas_Mobile.svg';
export {default as baasBalance} from './baas_balance.svg';
export {default as baasTotalInflow} from './baas_totalInflow.svg';
export {default as baasTotalOutflow} from './baas_totalOutflow.svg';
export {default as baasTotalRevenue} from './baas_totalRevenue.svg';
export {default as baasLock} from './baas_lock.svg';
export {default as successfulModal} from './successfulModal.svg';
export {default as totalInflowBaas} from './total_inflow_baas.svg';
export {default as totalOutflowBaas} from './total_outflow_baas.svg';
export {default as netCashflowBaas} from './net_cashflow_baas.svg';
export {default as baasMail} from './baas_mail.svg';
export {default as Tick} from './reconciliation_tick.svg';
export {default as NoTick} from './reconciliation_tick_overlay.svg';

// PNGs
export {default as map} from './map.png';
export {default as question} from './question.png';
export {default as dummy_id} from './dummy_id.png';
export {default as signUpBg} from './sign-up-bg.png';
export {default as placeholder} from './placeholder.png';
export {default as sandbox_dummy} from './sandbox_dummy.png';
export {default as ongoingIc} from './ongoing-ic.svg';
export {default as percentLoader} from './widget-percent-loader.png';
export {default as blogBg} from './blog-bg.png';
export {default as selfieLeft} from './selfie-left.png';
export {default as selfieFront} from './selfie-front.png';
export {default as governmentId} from './government-id.png';
export {default as map_marker} from './map-marker-icon.png';
export {default as widgetSelfie} from './widget-selfie.png';
export {default as easyOnboardTourOne} from './easy_onboard_tour_1.png';
export {default as easyOnboardTourTwo} from './easy_onboard_tour_2.png';
export {default as easyOnboardTourThree} from './easy_onboard_tour_3.png';
export {default as easyOnboardTourFour} from './easy_onboard_tour_4.png';
export {default as easyOnboardTourFive} from './easy_onboard_tour_5.png';
export {default as easyOnboardTourSix} from './easy_onboard_tour_6.png';
export {default as widgetHomeScreen} from './widget-homescreen.png';
export {default as portraitPlaceholder} from './portrait-placeholder.png';
export {default as placeholderLiveness} from './placeholder-liveness.png';
export {default as documentAnalysisImg1} from './document-analysis-img1.png';
export {default as documentAnalysisImg2} from './document-analysis-img2.png';
export {default as customerPhone} from './customerPhone.png';

// JPGs
