import AMLScreeningTour from './AMLScreeningTour';
import DevelopersTour from './DevelopersTour';
import EasyLookupTour from './EasyLookupTour';
import EasyOnboardTour from './EasyOnboardTour';
import EasyOnboardTour2 from './EasyOnboardTour2';
import TeamTour from './TeamTour';
import VerificationsTour from './VerificationsTour';

export {
  AMLScreeningTour,
  DevelopersTour,
  EasyLookupTour,
  EasyOnboardTour,
  EasyOnboardTour2,
  TeamTour,
  VerificationsTour,
};
