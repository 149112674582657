import React from 'react';
import {uploadCsv} from '../assets/images/images';

export default function FileInput({image = false, onChange, width, height, ...rest}) {
  return (
    <label className="mt-6">
      <input type="file" onChange={onChange} {...rest} />
      <div className="cursor-pointer bg-white80 py-[22px] flex flex-col items-center gap-[2px] text-center border border-brandBlue border-dashed rounded w-full font-normal">
        {image ?? (
          <img
            src={uploadCsv}
            alt=""
            className="mb-[2px]"
            width={width}
            height={height}
          />
        )}
        <p className="text-body">Drag and drop file here</p>
        <div className="text-body text-sm flex gap-[3px] items-baseline">
          <span className="hidden sm:block">or</span>
          <span className="text-brandBlue">click here to select</span>
          from your device
        </div>
      </div>
    </label>
  );
}
