export {
  signUp,
  login,
  forgotPassword,
  googleSignin,
  completeGoogleSignin,
  logOut,
  addCompany,
} from './queries/auth';

export {allApps} from './queries/dashboard';
