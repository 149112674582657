import React from 'react';
import {OnboardingCarousel} from '../components';
import {logo} from '../assets/images/images';
import {Link} from 'react-router-dom';
import {Constants} from '../helpers';

export default function Signup({children}) {
  const location = window.location.pathname;

  return (
    <section className="flex justify-between w-full min-h-screen md:gap-6 lg:gap-10 xl:gap-20">
      <div className="w-full md:w-[44.4%] max-w-full px-4 pt-8 md:pt-14 md:pr-0 md:pl-6 lg:pl-10 xl:pl-20 pb-10 md:text-left 2xl:flex 2xl:flex-col 2xl:max-w-2xl 2xl:mx-auto">
        <img
          src={logo}
          alt="Dojah logo"
          className="mx-auto md:m-0"
          width={64}
          height={29}
        />
        <h1 className="mt-10 text-xl font-medium text-center md:text-left text-grey">
          Welcome to Dojah!
        </h1>
        <p className="text-center md:text-left text-sm text-body mt-2 max-w-[480px]">
          Businesses use Dojah&apos;s API to verify, onboard and manage user
          identity across Africa.
        </p>

        {children}
        {location.split('/')[2] !== 'add-company' && (
          <p className="mt-10 text-sm font-medium text-center text-body md:text-left">
            Already have an account?{' '}
            <span className="text-brandBlue">
              <Link to={Constants.PAGES_URL.SIGNIN}>Sign in</Link>
            </span>
          </p>
        )}
      </div>
      <OnboardingCarousel />
    </section>
  );
}
